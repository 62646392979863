import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-animals-zakat',
  templateUrl: './animals-zakat.component.html',
  styleUrls: ['./animals-zakat.component.scss'],
})
export class AnimalsZakatComponent implements OnInit {
  animalsForm:FormGroup;
  constructor(
    private translateService:TranslateService
  ) { }

  ngOnInit() {
    this.animalsForm = new FormGroup({
      cowsAmount: new FormControl(),
      camelsAmount:new FormControl(),
      sheepsAmount:new FormControl(),
    })
  }

  zakatCows;
  calcCows() {
    let cowsAmount = this.animalsForm.value.cowsAmount 
    if(cowsAmount && cowsAmount< 30) {
      // console.log('no zakat')
      this.translateService.get('zakat.noCowsZakat').subscribe(r => {
        this.zakatCows = [r]
      })
    } else if (cowsAmount == 30 && cowsAmount < 40) {
      this.translateService.get(['zakat.oneYoungCow', 'zakat.youngDef']).subscribe(r => {
        this.zakatCows = [ r['zakat.oneYoungCow'] ,  r['zakat.youngDef']]
      })
    } else if (cowsAmount > 39 && cowsAmount < 60) {
      this.translateService.get(['zakat.oldCow', 'zakat.oldDef']).subscribe(r => {
        this.zakatCows = [r['zakat.oldCow'] ,  r['zakat.oldDef']]
      })
    } else if (cowsAmount > 59 && cowsAmount < 70) {
      this.translateService.get(['zakat.twoYoungCow', 'zakat.youngDef']).subscribe(r => {
        this.zakatCows = [r['zakat.twoYoungCow'] , r['zakat.youngDef']]
      })
    } else if (cowsAmount > 69 && cowsAmount < 80) {
      this.translateService.get(['zakat.oneYoungOneOld', 'zakat.youngDef',  'zakat.oldDef']).subscribe(r => {
        this.zakatCows = [r['zakat.oneYoungOneOld'],  r['zakat.youngDef'] ,  r['zakat.oldDef']]
      })
    } else if (cowsAmount > 79 && cowsAmount < 90) {
      this.translateService.get(['zakat.twoOldCow', 'zakat.oldDef']).subscribe(r => {
        this.zakatCows = [r['zakat.twoOldCow'],  r['zakat.oldDef']]
      })
    } else if (cowsAmount > 89 && cowsAmount < 100) {
      this.translateService.get(['zakat.threeYoungCow', 'zakat.youngDef']).subscribe(r => {
        this.zakatCows =[ r['zakat.threeYoungCow'],  r['zakat.youngDef']]
      })
    } else if (cowsAmount > 99 && cowsAmount < 110) {
      this.translateService.get(['zakat.twoYoungCowOneOld', 'zakat.youngDef',  'zakat.oldDef']).subscribe(r => {
        this.zakatCows = [r['zakat.twoYoungCowOneOld'],  r['zakat.youngDef'] ,  r['zakat.oldDef']]
      })
    } else if (cowsAmount > 109 && cowsAmount < 120) {
      this.translateService.get(['zakat.twoOldCowOneYoung','zakat.youngDef',  'zakat.oldDef']).subscribe(r => {
        this.zakatCows = [r['zakat.twoOldCowOneYoung'],  r['zakat.youngDef'] ,  r['zakat.oldDef']]
      })
    } else if (cowsAmount > 119 && cowsAmount < 130) {
      this.translateService.get(['zakat.FourYoungCowThreeOld','zakat.youngDef',  'zakat.oldDef']).subscribe(r => {
        this.zakatCows = [r['zakat.FourYoungCowThreeOld'] ,  r['zakat.youngDef'] ,  r['zakat.oldDef']]
      })
    }

  }



  zakatSheeps;
  calcSheeps() {
    this.translateService.get(['zakat.fromSheeps', 'zakat.noSheepsZakat']).subscribe(r => {
      let translate = r['zakat.fromSheeps']
      let value = this.animalsForm.value.sheepsAmount;
      if(value && value < 40) {
        this.zakatSheeps = r['zakat.noSheepsZakat'];
      } else if (value > 39 && value < 121) {
        this.zakatSheeps = 1 + ' ' + translate
      } else if (value > 120 && value < 201) {
        this.zakatSheeps = 2 + ' ' + translate
      } else if (value > 200 && value < 400) {
        this.zakatSheeps = 2 + ' ' + translate
      } else {
        this.zakatSheeps = Math.floor(value/100) + ' ' + translate
      }
    })

    
  }


  zakatCamel;
  calcCamels() {
    let value = this.animalsForm.value.camelsAmount;
    if(value && value < 5) {
      // console.log('no camels')
      this.translateService.get(['zakat.noCamelZakat']).subscribe(r => {
        this.zakatCamel = [r['zakat.noCamelZakat']]
      })
    } else if(value > 4 && value < 10) {
      this.translateService.get(['zakat.sheep']).subscribe(r => {
        this.zakatCamel = [r['zakat.sheep']]
      })
    } else if(value > 9 && value < 15) {
      this.translateService.get(['zakat.twoSheep']).subscribe(r => {
        this.zakatCamel = [r['zakat.twoSheep']]
      })
    }  else if(value > 14 && value < 20) {
      this.translateService.get(['zakat.threeSheep']).subscribe(r => {
        this.zakatCamel = [r['zakat.threeSheep']]
      })
    }  else if(value > 21 && value < 25) {
      this.translateService.get(['zakat.fromSheeps']).subscribe(r => {
        this.zakatCamel = [4 + ' ' + r['zakat.fromSheeps']]
      })
    }  else if(value > 24 && value < 36) {
      this.translateService.get(['zakat.laborCamel']).subscribe(r => {
        this.zakatCamel = [r['zakat.laborCamel']]
      })
    } else if (value > 35 && value < 46) {
      this.translateService.get(['zakat.labonCamel']).subscribe(r => {
        this.zakatCamel = [r['zakat.labonCamel']]
      })
    } else if (value > 45 && value < 61) {
      this.translateService.get(['zakat.haqaCamel']).subscribe(r => {
        this.zakatCamel = [r['zakat.haqaCamel']]
      })
    } else if (value > 61 && value < 76) {
      this.translateService.get(['zakat.gaz3aCamel']).subscribe(r => {
        this.zakatCamel = [r['zakat.gaz3aCamel']]
      })
    }  else if (value > 75 && value < 89) {
      this.translateService.get(['zakat.twoLabonCamel', 'words.labon']).subscribe(r => {
        this.zakatCamel = [r['zakat.twoLabonCamel'], r['words.labon']]
      })
    } else if (value > 90 && value < 121) {
      this.translateService.get(['zakat.twoHaqaCamel', 'words.haqa']).subscribe(r => {
        this.zakatCamel = [r['zakat.twoHaqaCamel'], r['words.haqa']]
      })
    }
  }


  calcZakatForAll() {
    this.calcCows();
    this.calcSheeps()
    this.calcCamels()
  }

}
