import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-zakat-capital',
  templateUrl: './zakat-capital.component.html',
  styleUrls: ['./zakat-capital.component.scss'],
})
export class ZakatCapitalComponent implements OnInit {
  capitalForm:FormGroup;

  constructor() { }

  ngOnInit() {
    this.capitalForm = new FormGroup({
      yearType: new FormControl('.025775'),
      goldPrice: new FormControl('', Validators.required),
      revenues: new FormGroup({
        rentRevenue: new FormControl(),
        revenueOwed: new FormControl(),
        otherRevenues: new FormControl(),
      }),
      expenses: new FormGroup({
        rentExpenses: new FormControl(),
        wages: new FormControl(),
        taxes: new FormControl(),
        otherExpenses: new FormControl(),
      }),
      debtsAndNeeds: new FormControl()

    })

    this.capitalForm.valueChanges.subscribe(() => {
      this.zakatValue = '';
      this.noZakat = false;
    })
  }

  zakatValue ;
  noZakat = false;

  calcZakat() {
    this.capitalForm.markAllAsTouched();
    if(this.capitalForm.valid) {
      let value = this.capitalForm.value;
      let revenues = (value.revenues.rentRevenue || 0) + (value.revenues.revenueOwed || 0) + (value.revenues.otherRevenues || 0)
      let expenses = (value.expenses.rentExpenses || 0) + (value.expenses.wages || 0) + (value.expenses.taxes || 0) + (value.expenses.otherExpenses || 0)
      let netWorth = (revenues  - expenses) - value.debtsAndNeeds;
      let nisab = value.goldPrice * 85;
      if(netWorth < nisab) {
        // no zakat
        this.noZakat = true;
      } else {
        this.zakatValue = (netWorth * +value.yearType).toFixed(2)
      }
    }
  }

}
