import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { UiService } from 'src/app/services/ui.service';
import { DonationsService } from 'src/app/services/donations.service';
import { CartService } from 'src/app/services/cart.service';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { DonateModalComponent } from '../donate-modal/donate-modal.component';

@Component({
  selector: 'app-donation-box',
  templateUrl: './donation-box.component.html',
  styleUrls: ['./donation-box.component.scss'],
})
export class DonationBoxComponent implements OnInit {
  @Input('data') data:any = {};
  @Input('cartMode') cartMode:boolean;
  @Input('isInModal') isInModal = false;

  constructor(
    public sharedService:SharedService,
    public uiService:UiService,
    private donationsService:DonationsService,
    private cartService:CartService,
    private router:Router,
    private modalCtrl:ModalController
    ) { }

  ngOnInit() {
    let donation_prices = JSON.parse(this.data.donation_prices);
    if(donation_prices.length>0){
      this.amounts = donation_prices;
    }

    this.data.count = 1;
  } 

  amounts

  setAmount(a) {
    this.data.amount = a;
  }
  
  addValue() {
    this.changeCartValue()
  }

  changeCartValue() {
    
  }

  cacheItem(){
    let data = JSON.parse(JSON.stringify(this.data))
    if( typeof data.images == 'string') {
      data['images'] = JSON.parse(data['images'])
    } 
    if(typeof data.donation_prices == 'string') data['donation_prices'] = JSON.parse(data['donation_prices'])
    this.donationsService.activeDonation = data;
    if(this.isInModal) this.modalCtrl.dismiss()
  }
  donate(){
    this.modalCtrl.create({
      component:DonateModalComponent, 
      componentProps:{
        data:this.data,
        type:'case'
      }
    }).then(m => m.present())
  }
}
