import { Component, OnInit, Input } from '@angular/core';
import { CartService } from 'src/app/services/cart.service';
import { SharedService } from 'src/app/services/shared.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-kafalat-card',
  templateUrl: './kafalat-card.component.html',
  styleUrls: ['./kafalat-card.component.scss'],
})
export class KafalatCardComponent implements OnInit {
  @Input('cardData') cardData;
  @Input('cartMode') cartMode:boolean;
  constructor(
    private cartService:CartService,
    public sharedService:SharedService,
    public uiService:UiService
  ) { }

  ngOnInit() {
    // console.log('chainging', this.cardData)
    this.cardData['count'] = 1;
    this.calcDate()
  }

  changeCartValue() {
    if(this.cartMode) {
      let d = this.cardData;
      d['itemType'] = 'sponsorship'
      this.cartService.cartItems = d
    }
  }

  calcDate() {
  //   // console.log('nnnnn')
     
  //   var ageDate = new Date(+new Date() - +new Date(this.cardData.birthdate));
  //   // console.log(ageDate)
  //   let x =  Math.abs(ageDate.getUTCFullYear() - 1970);
  //   this.cardData['age'] = x;  
  //  // console.log(this.calcDate)

   this.cardData['age'] =  Math.abs(new Date(+new Date() - +new Date(this.cardData.birthdate)).getUTCFullYear() - 1970)
  //  // console.log('xxx', x)
  //  this.calcDate['age'] = x;  
  }

  cacheItem(){
    this.sharedService.activeKafala = this.cardData;
  }
}
