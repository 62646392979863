import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DonationsService {

  constructor() { }

  _activeDonation;

  set activeDonation(data){
    this._activeDonation = data;
  }
  get activeDonation(){
    return this._activeDonation;
  }
}
