import { Component, OnInit, Output,EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { Api } from 'src/app/services/api.endpoints';
import { finalize } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input';

@Component({
  selector: 'app-login-register-with-phone',
  templateUrl: './login-register-with-phone.component.html',
  styleUrls: ['./login-register-with-phone.component.scss'],
})
export class LoginRegisterWithPhoneComponent implements OnInit {
  loginForm;
  activeCodeForm;
  redirectUrl

  CountryISO = CountryISO;
  SearchCountryField = SearchCountryField
  TooltipLabel = TooltipLabel;
  
  @Output('startLoginWithPhone') startLoginWithPhone: EventEmitter<boolean> = new EventEmitter();
  @Input('registerPage') registerPage:boolean = false;

  
  constructor(
    private activatedRoute:ActivatedRoute,
    private uiService:UiService,
    private dataService:DataService,
    private router:Router,
    private sharedService:SharedService
  ) { }

  ngOnInit() {
    this.loginForm = new FormGroup({
      // email:new FormControl('', Validators.required),
      // password:new FormControl('', Validators.required)
      phone:new FormControl('', [Validators.required])
    });

    this.activeCodeForm =  new FormGroup({
      code:new FormControl('', Validators.required)
    });
    this.activatedRoute.queryParams.subscribe( params  => this.redirectUrl = params.redirectUrl )


  }


  getInput(input) {
    return this.loginForm.controls[input]
  }
  
  firstStep
  
  verify_token
  async doLogin() {
    console.log(this.loginForm)
    this.loginForm.markAllAsTouched();
    if(this.loginForm.valid) {
      this.startLoginWithPhone.emit(null)
      this.uiService.show_loading();
      let phone = this.loginForm.value.phone.internationalNumber.replace(/ /gi ,"")
      this.dataService.post(Api.login, {phone:phone}).pipe(finalize(() => this.uiService.hide_loading())).subscribe(r => {
        console.log('r', r)
        this.verify_token = r['data'].verify_token
        this.firstStep = true
      })

      // 
    }
    // this.loadingCtrl.create({
    //   message:'loading...'
    // }).then(l => {
    //   l.present();

    //   setTimeout(() => {
    //     localStorage.setItem('token', 'fakeToken')
    //     l.dismiss()
    //     this.router.navigate([this.redirectUrl || '/'])
    //   }, 1000)
    // })
  }

  doActivate() {
    this.activeCodeForm.markAllAsTouched();
    if(this.activeCodeForm.valid) {
      this.uiService.show_loading();
      this.dataService.post(Api.activate, {
        code:this.activeCodeForm.controls['code'].value,
        token : this.verify_token
      }).pipe(finalize(() => this.uiService.hide_loading())).subscribe(r => {
         this.dataService.updateToken()
        localStorage.setItem('token', r['data'].token)
        localStorage.setItem('profile', JSON.stringify(r['data'].profile))
        this.router.navigate([decodeURIComponent(this.redirectUrl) || '/'], {replaceUrl:true});
        this.sharedService.isLoggedIn = true;
      })
    }
  }


}
