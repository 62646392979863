import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { DataService } from './data.service';
import {Api, site_url} from "./api.endpoints";
import { Assets, Currency } from '../models/models';

import {JwtHelperService} from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { UiService } from './ui.service';
import { Title } from '@angular/platform-browser';
declare var window;

import { Meta } from '@angular/platform-browser';
import { Platform } from '@ionic/angular';
import { Push } from '@ionic-native/push/ngx';

interface HeaderData {
  title:string;
  isStatic:boolean;
  metaTags?: {
    name: string;
    content: string;
  }[]
}
@Injectable({
  providedIn: 'root'
})
export class SharedService  {

  activeKafala;
  
  appLang:string ;

  mobile = false;
  isCordova = false ;



  activeStory;
  activeCourse;
  atHome = false;
  assets:Assets;
  isLoggedIn = false;
  // Our encryption key
  encryptionKey = `46Bthk_OR+8v|,=;;t#mJ'DgU22qAbDTWJ?)]d~?LliKVn9VRIHhuFS_6^t_-90`;
  constructor(
    public translate: TranslateService ,
    private dataService:DataService,
    private jwt:JwtHelperService,
    private router:Router,
    private titleService:Title,
    private meta: Meta,
    private platform:Platform,
    private push:Push
    ) {
 
    if(window.innerWidth < 787) {
       
      this.mobile = true;
    }

    if(this.platform.is('cordova')) this.isCordova = true;
    this.loadAssets();
  
    

  }

 
  showSmallNav = false;

  NavElements
  // contentScroll(scrollTop) {
  //   // if(this.mobile || !this.NavElements || this.NavElements.length  === 0 || this.isCordova) return ;

  //   // if(scrollTop > (this.NavElements[this.NavElements.length - 1].clientHeight + 20))  {
  //   //   this.showSmallNav = true;
  //   // } else {
  //   //   this.showSmallNav = false;

  //   // }
 
  // }

  
  links = [
    
    {
      title:'campaigns',
      path:'/donations'
    },
    {
      title:'aboutUs',
      path:'/about-us'
    },
    {
      title:'news',
      path:'/news'
    },
    {
      title:'contactUs',
      path:'/contact-us'
    },
    


  ]

  loadAssets(){
    let assets = this.decode(localStorage.getItem('Istkama_Assets'));
   // // console.log(assets);

    if(!assets) {
      assets = {
        default_currency :{

        }
      }
    } else if (assets && !assets.default_currency) {
      assets['default_currency'] = {}
    }
    

    this.assets = assets;
    // if(!assets) {
        this.dataService.get(Api.assets).subscribe((res:any)=>{
           if(res.data){
             if(this.assets && this.assets.default_currency.id) res.data['default_currency'] = this.assets.default_currency
             this.assets = res.data;
             localStorage.setItem('Istkama_Assets',this.encode(res.data))
           }
        })
    // }else{
    //   this.assets = assets;
    // }
  }

  _headerTitle:HeaderData = {title:'', isStatic:false}
  set headerTitle (data:HeaderData) {
    this._headerTitle = data;
    if(data.isStatic) {
      this.titleService.setTitle(data.title);
    } else {
      this.translate.get('menu.'+data.title).subscribe(res=>{
        this.titleService.setTitle(res);
      });
    }

    // console.log(data.metaTags)

    if(data.metaTags)this.meta.addTags(data.metaTags);
   
  }
  
  setTitle(title, isStatic){
   
  }

  
  setLang(){
      this.appLang = localStorage.getItem('appLang');
      if(!this.appLang || this.appLang === undefined ) {
        this.appLang = 'ar';
        localStorage.setItem('appLang','ar');
      };
      this.translate.addLangs(['en', 'ar']);
      this.translate.setDefaultLang('ar');
      if(this.appLang == 'ar' ) {
        document.getElementsByTagName("body")[0].className = 'ar'
      } else {
        document.getElementsByTagName("body")[0].className = 'en'
      }
      this.translate.use(this.appLang);
  }

  switchLang(lang = null) {

   // alert(this.router.url)
    if(lang == null ) lang = (this.appLang == 'ar')?'en':'ar';

    localStorage.setItem('appLang' , lang);
    this.appLang = lang;
    window.appLang = lang;
    this.translate.use(lang);
    if( lang == 'ar' ) {
      document.getElementsByTagName("body")[0].className = 'ar';
    } else {
      document.getElementsByTagName("body")[0].className = 'en';
    }
   // this.router.navigate([this.appLang+this.router.url],{ replaceUrl: true });

    window.location.href=this.appLang+this.router.url;
    //location.reload();
  }

  // Changing default currency 
  changeCurrency(id){
      let currency = this.assets.currencies.filter(c => c.id === id)[0]
      currency.is_default = true;
      this.assets.default_currency = currency;
      localStorage.setItem('Istkama_Assets',this.encode(this.assets));
  }
  toUSD(amount){

    if(this.assets.default_currency.symbole_en != 'usd'){
      let usd = this.assets.currencies.filter(c => c.symbole_en === 'usd')[0];
      if(usd){
        return  ( parseFloat(amount) / usd.amount ).toFixed(2) 
      }else{
        return ( parseFloat(amount) / 0.38  ).toFixed(2) 
      }
     
    }else{
      return amount;
    }
    // if(this.assets.default_currency){
    //     return `<span class="amount">${( parseFloat(value) / this.sharedService.assets.default_currency.amount ).toFixed(2) }</span> <span class="label">${this.sharedService.assets.default_currency['symbole_'+this.sharedService.appLang]}</span>`
    // }
   
  }

  // Crypt Data saved to localStorage
  encode(data) {
    return CryptoJS.AES.encrypt(JSON.stringify(data),this.encryptionKey);
  }

  // Decoding Data saved to localStorage 
  decode(data) {
    try {
      let bytes = CryptoJS.AES.decrypt(data, this.encryptionKey);
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (error) {
      return false
    }
  }


  checkAuth(defUrl?) {
    let token = localStorage.getItem('token')
    if(!token) {
      this.router.navigate(['/auth/login'], {queryParams:{redirectUrl: defUrl ? defUrl : this.router.url.substr(1)}})
      return false;
    }
    else {
    
      if(this.jwt.isTokenExpired(token)) {
        this.router.navigate(['/auth/login'], {queryParams:{redirectUrl: defUrl ? defUrl : this.router.url.substr(1)}})
        return false;
      } 
      return !this.jwt.isTokenExpired(token)
    }
  }

  checkAuthNoRoute() {
    let token = localStorage.getItem('token')
    if(!token) this.isLoggedIn = false;
    else {
      this.isLoggedIn = !this.jwt.isTokenExpired(token)
    }

    return this.isLoggedIn;
  }


  
}













// [
//   {
//     name:'title',
//     content:''
//   },
//   {
//     name:'description',
//     content:''
//   },
//   {
//     name:'og:type',
//     content:''
//   },
//   {
//     name:'og:url',
//     content:''
//   },
//   {
//     name:'og:description',
//     content:''
//   },
//   {
//     name:'og:description',
//     content:'og:image'
//   },
//   {
//     name:'og:image',
//     content:''
//   },
//   {
//     name:'twitter:card',
//     content:''
//   },
//   {
//     name:'twitter:url',
//     content:''
//   },
//   {
//     name:'twitter:title',
//     content:''
//   },
//   {
//     name:'twitter:description',
//     content:''
//   },
//   {
//     name:'twitter:image',
//     content:''
//   },

// ]