import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { AlertController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'donation-actions',
  templateUrl: './donation-actions.component.html',
  styleUrls: ['./donation-actions.component.scss'],
})
export class DonationActionsComponent implements OnInit {
  @Input('type') type;
  @Input('data') data;
  @Input('cartMode') cartMode;
  @Input('isInModal') isInModal = false;

  constructor(
    private router:Router,
    private cartService:CartService,
    private alertCtrl:AlertController,
    private translate:TranslateService,
    private modalCtrl:ModalController
  ) { }

  ngOnInit() {}

  donateNow() {

    if(this.checkAmount()) {
      if(this.isInModal) this.modalCtrl.dismiss()

      this.addToCart();
      this.router.navigate(['/cart'])
    }
    
  }

  addToCart() {
    // if(this.checkAuth()) {
     if(this.checkAmount()){
        let d = this.data;
        d['itemType'] = this.type
        this.cartService.cartItems = d
     }
         // } 
  }

  checkAmount(){
    if(!this.data.amount){
      this.translate.get('cart.no_amount').subscribe(res=>{
        this.cartService.presentToast(res,'danger');
      })
      return false;
    } 
    return true;
  }

  checkAuth() {
    

    // let token = localStorage.getItem('token');
    // if(token) {
    //    this.router.navigate(['/cart'])
      
    // } else {
    //   // // console.log(this.router.url)
    //   this.router.navigate(['/auth'], {queryParams:{redirectUrl:this.router.url}})
    //   return false;
    // }
  }


  removefromCart() {
    this.alertCtrl.create({
      message:'Do You Want To Remove This Item From Cart ?',
      buttons:[
        {
          text:'Remove',
          handler:() => {
            // console.log(this.data.itemType,  this.data.id)
            delete this.cartService._cartItems[this.type + '_' + this.data.id];
            // console.log(this.cartService._cartItems)
          }
        },
        {
          text:'cancel',
          role:'cancel'
        }
      ]
    }).then(a => a.present())
  }
}
