import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({
  name: 'video'
})
export class VideoPipe implements PipeTransform {

   
  constructor(private sanitizer: DomSanitizer) {}
  transform(value: any, ...args: unknown[]): unknown {
      
    let html ='';
    if(value.includes('youtube')) {
        const video_id = value.split('watch?v=')[1];
   
       html = `
      <iframe width="560" height="315" src="https://www.youtube.com/embed/${video_id}?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      `;
   
    }else if(value.includes('youtu.be')){
        const video_id = value.split('youtu.be/')[1];
         
        html = `
        <iframe width="560" height="315" src="https://www.youtube.com/embed/${video_id}?rel=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        `;
 
    }else{
      html = `<video width="560" height="315" controls>
                <source src="${value}" >
                Your browser does not support the video tag.
              </video>
              `;
    }


    return this.sanitizer.bypassSecurityTrustHtml(html);
    
  }

}
