import { Injectable } from '@angular/core';
// import { ErrorDialogService } from './errordialog.service';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './data.service';
import { UiService } from './ui.service';
import { AlertController } from '@ionic/angular';
// import { ToastrService } from 'ngx-toastr';
// import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  errors = {
    "400_ar": "البيانات المدخلة غير صحيحة",
    "404_ar": "غير موجود",
    "0_ar":"خطأ في الإتصال بالإنترنت",
    "0_en":"Error in connecting with internet",
    "unknown_ar":"خطأ غير معروف",
    "error_ar":"خطأ",
    "400_en": "You have entered invalid data",
    "404_en": "Not found",
    "unknown_en":"Unknow error",
    "error_en":"Error",
    "ok_ar":"حسنا",
    "ok_en":"OK"
  }

  constructor(
    private dataService:DataService,
    private alertCtrl:AlertController,
    // private uiService:UiService
    ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(request).pipe(
        map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {

            }else{

            }
            return event;
        }),
        catchError((error: HttpErrorResponse) => {
          this.dataService.isLoading = false;
          if(error.status === 400) this.openToast('400');
          else if (error.status === 0) this.openToast('0');
          else if (error.status === 404) this.openToast('404');
          else this.openToast('unknown')
          return throwError(error);
        }));
  }

  openToast(message:string): void {
    let lang = localStorage.getItem('appLang');
    !lang ? lang = 'ar' : ''
    this.alertCtrl.create({
      subHeader:this.errors['error_' + lang],
      message:this.errors[message+ '_' + lang],
      buttons:[this.errors['ok'+ '_' + lang]]
    }).then(a => a.present())
  }
    
}
