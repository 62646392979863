import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Api, site_url } from './api.endpoints';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingController, ToastController } from '@ionic/angular';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  
   // private preloaderStateSource = new Subject<string>();

   constructor(private translate: TranslateService ,private titleService:Title  , private zone:NgZone , private _sanitizer: DomSanitizer , public loadingController: LoadingController , private sharedService:SharedService,    private toastController: ToastController,
    ) {
    this.setLoader();
  }

  setTitle(title){
    if(title==null) return ;
      this.translate.get(title).subscribe(res=>{
        title = res;
    });
    this.titleService.setTitle(title);
  }

  async setLoader(){
    
  }
  loading;
  isLoading=false;
  async show_loading(){
    this.isLoading = true;
    return await this.loadingController.create({
      message: (this.sharedService.appLang == 'ar')?'جاري التحميل ...':'Loading...',
      id:'loader'
    }).then(a => {
        a.present().then(() => {
         // console.log('presented',a);
          if (!this.isLoading) {
            a.dismiss().then(() => {});
          }
        });
    });

  }

  async hide_loading(loader = null){
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() =>  {});     
  }

  imageUrl(img , Sanitize = false){
    if(Sanitize) 
    return this._sanitizer.bypassSecurityTrustStyle(`url(${Api.uploads+img})`);
    else  return Api.uploads+img;
  }

  noImage(elem,img=null){
    if(img==null) return elem.src=site_url+'assets/imgs/no-image.png';
    else return elem.src=site_url+'assets/'+img;
  }


  error_toast(msg_label){

     
    this.translate.get(msg_label).subscribe(res=>{
      this.presentToast(res,'danger')
   })

   
  
 }


 async presentToast(msg,type='success') {
   

  const toast = await this.toastController.create({
    message: msg,
    duration: 2000,
    color:(type=='danger')?"danger":"success",
    position:"top"
  });
  toast.present();
}


 succes_toaster(msg_label){
    
    
    this.translate.get(msg_label).subscribe(res=>{
       this.presentToast(res,'success')
    })
    
  }





}
