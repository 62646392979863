import { NgModule , NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { CommonModule } from '@angular/common';
// import { SharedModule } from '../../shared/shared.module';
// import { MatInputModule } from '@angular/material/input';
import { SharedModule } from '../shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { KafalatCardComponent } from './kafalat-card/kafalat-card.component';
import { DonationActionsComponent } from './donation-actions/donation-actions.component';
import { DonationCardComponent } from './donation-card/donation-card.component';
import { HeaderComponent } from './header/header.component';
import { DonationBoxComponent } from './donation-box/donation-box.component';
import { ShareComponent } from './share/share.component';
import { MainKafalaCardComponent } from './main-kafala-card/main-kafala-card.component';

import { ShareModule } from 'ngx-sharebuttons';
import { SocialmediaComponent } from './socialmedia/socialmedia.component';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { AnimalsZakatComponent } from './animals-zakat/animals-zakat.component';
import { ZakatCapitalComponent } from './zakat-capital/zakat-capital.component';
import { LoginRegisterWithPhoneComponent } from './login-register-with-phone/login-register-with-phone.component';
import { SharePopoverComponent } from './share-popover/share-popover.component';
import { UrgentCasesComponent } from './urgent-cases/urgent-cases.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { DonateModalComponent } from './donate-modal/donate-modal.component';


// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [
   FooterComponent,
   HeaderComponent,
   KafalatCardComponent,
   DonationActionsComponent,
   DonationCardComponent,
   DonationBoxComponent,
   ShareComponent,
   MainKafalaCardComponent,
   SocialmediaComponent,
   AnimalsZakatComponent,
   ZakatCapitalComponent,
   LoginRegisterWithPhoneComponent,
   SharePopoverComponent,
   UrgentCasesComponent,
   DonateModalComponent
   
  ],
  entryComponents:[
    SharePopoverComponent,
    UrgentCasesComponent,
    
  ],
  imports: [
    CommonModule,
    SharedModule,
    // MatInputModule,
    ShareModule,
    NgxIntlTelInputModule,
    BsDropdownModule.forRoot(),
  ],
  exports:[
    FooterComponent,
    HeaderComponent,
    KafalatCardComponent,
    DonationActionsComponent,
    DonationCardComponent,
    DonationBoxComponent,
    ShareComponent,
    MainKafalaCardComponent,
    SocialmediaComponent,
    AnimalsZakatComponent,
    ZakatCapitalComponent,
    LoginRegisterWithPhoneComponent,
    UrgentCasesComponent,
    DonateModalComponent
  ],
  providers:[InAppBrowser],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class ComponentsModule { }
