import { Pipe, PipeTransform } from '@angular/core';
import { SharedService } from '../services/shared.service';

@Pipe({
  name: 'currency',
  pure:false
})
export class CurrencyPipe implements PipeTransform {

  constructor(private sharedService:SharedService){

  }
  transform(value: any, ...args: unknown[]): unknown {
    if(this.sharedService.assets.default_currency){
        return `<span class="amount">${ parseFloat(( parseFloat(value) / this.sharedService.assets.default_currency.amount ).toFixed(2)) }</span> <span class="label">${this.sharedService.assets.default_currency['symbole_'+this.sharedService.appLang]}</span>`
    }
    return null;
  }

}
