import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-donate-modal',
  templateUrl: './donate-modal.component.html',
  styleUrls: ['./donate-modal.component.scss'],
})
export class DonateModalComponent implements OnInit {
  data;
  type;
  amounts = [100,200,500];
  constructor(    
    private router:Router,

    private translate:TranslateService,    
    private cartService:CartService,
    private modalCtrl:ModalController
    ) {
   }

  ngOnInit() {   
     console.log(this.data);
     if(this.data.donation_prices){
      let donation_prices = JSON.parse(this.data.donation_prices);
      if(donation_prices.length>0){
        this.amounts = donation_prices;
      }
     }
   
    this.data.share_to ;
  }

  setAmount(a) {
    this.data.amount = a;
  }
  donate(){
   
      if(this.checkAmount()) {
        this.modalCtrl.dismiss()
        this.addToCart();
         this.router.navigate(['/cart/payment'])
      }
      
 
  
  }

  checkAmount(){
    if(!this.data.amount){
      this.translate.get('cart.no_amount').subscribe(res=>{
        this.cartService.presentToast(res,'danger');
      })
      return false;
    } 
    return true;
  }

  addToCart() {
    // if(this.checkAuth()) {
     if(this.checkAmount()){
        let d = this.data;
        d['itemType'] = this.type;
        this.cartService.emptyCart();
        this.cartService.cartItems = d
     }
         // } 
  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      'dismissed': true
    });
  }
}
