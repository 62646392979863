import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { UiService } from 'src/app/services/ui.service';
import { DataService } from 'src/app/services/data.service';
import { Api } from 'src/app/services/api.endpoints';
import { finalize, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-donation-card',
  templateUrl: './donation-card.component.html',
  styleUrls: ['./donation-card.component.scss'],
})
export class DonationCardComponent implements OnInit {

  activeTab = 'hamalat';
  hamalatForm:FormGroup;
  moneyAmount = 0;
  amounts = [50, 100,300,500]
  
  kafalatForm:FormGroup;
  zakatForm:FormGroup;



  kafalatAmounts = [300,400,500,600]
  kafalatnumbers = [1,2,3,4,5,6,7,8,9,10]

  kafalatAmount = 0;




  subCategories

  constructor(
    public sharedService:SharedService,
    public uiService:UiService,
    private dataService:DataService,
    private router:Router,
    private cartService:CartService,
    private translate:TranslateService
  ) { }

  ngOnInit() {
    this.hamalatForm = new FormGroup({
      country: new FormControl(''),
      category:new FormControl(''),
      subCategories:new FormControl(''),
      amount:new FormControl('', Validators.required),
      case:new FormControl('', Validators.required),
    })

   
  //  this.loadBothAtStart();

    this.hamalatForm.controls.country.valueChanges.subscribe(v => {
      // this.moneyAmount =  v.amount
      setTimeout(() => {
        this.loadHamalt()
      }, 100)
    })
    this.hamalatForm.controls.category.valueChanges.subscribe(v => {
      // this.moneyAmount =  v.amount
      setTimeout(() => {
        this.loadHamalt()
      }, 100)
    })
    this.hamalatForm.controls.subCategories.valueChanges.subscribe(v => {
      // this.moneyAmount =  v.amount
      setTimeout(() => {
        this.loadHamalt()
      }, 100)
    })


    this.kafalatForm = new FormGroup({
      category: new FormControl(''),
      country: new FormControl(''),
      amount: new FormControl('', Validators.required),
      guaranteed: new FormControl('', Validators.required)
    });


    this.kafalatForm.controls.category.valueChanges.subscribe(v => {
      // this.moneyAmount =  v.amount
      setTimeout(() => {
        this.loadSponsorships()
      }, 100)
    })
    this.kafalatForm.controls.country.valueChanges.subscribe(v => {
      // this.moneyAmount =  v.amount
      setTimeout(() => {
        this.loadSponsorships()
      }, 100)
    })


    this.zakatForm = new FormGroup({
      type: new FormControl(''),
      number: new FormControl(''),
      amount: new FormControl(''),
    })

  }


  loadBothAtStart() {
    this.uiService.show_loading();
    this.dataService.get(Api.cases, {per_page: 1000})
    .pipe(switchMap(( (res:any) => {
      this.projects =  res.data.data
      return this.dataService.get(Api.sponsorships, {per_page: 1000})
    })))
    .subscribe((res:any)=>{
      this.uiService.hide_loading()
      // console.log(res)
      this.guaranteedData = res.data.data
    }, () => this.uiService.hide_loading())

     finalize(() => this.uiService.hide_loading())
  }

  loadSubCategory(catId){
    this.subCategories = [];
    this.hamalatForm.controls.subCategories.setValue('')
    for (var i = 0; i < this.sharedService.assets.categories.length; i++) {
      if (this.sharedService.assets.categories[i].id == catId) {
        this.subCategories = this.sharedService.assets.categories[i].childrens;
        break;
      }
    }
  }




  setAmount(a) {
    // this.moneyAmount = a;
    this.hamalatForm.controls.amount.setValue(a)
  }

  projects;


  loadHamalt () {
    let data = JSON.parse(JSON.stringify(this.hamalatForm.value));
    delete data.amount;
    delete data.case
    data['per_page'] = 10000
    this.uiService.show_loading();
    this.dataService.get(Api.cases, data)
    .pipe(finalize(() => this.uiService.hide_loading()))
    .subscribe((res:any)=>{
      // console.log(res)
      this.projects =  res.data.data
    })
  }


  guaranteedData
  loadSponsorships(){
    let data = JSON.parse(JSON.stringify(this.kafalatForm.value));
    delete data.amount;
    delete data.guaranteed;
    data['per_page'] = 10000;
    this.uiService.show_loading();
    this.kafalatForm.controls.guaranteed.setValue('')
    this.kafalatForm.controls.amount.setValue('')

    this.dataService.get(Api.sponsorships, data)
    .pipe(finalize(() => this.uiService.hide_loading()))
    .subscribe((res:any)=>{
      // console.log(res)
      this.guaranteedData = res.data.data
      // console.log(this.guaranteedData)
    })
  }


  donateHamaltNow() {
    // console.log(this.hamalatForm)
    if(this.hamalatForm.valid) this.donateNow(Object.assign(this.hamalatForm.value.case, {amount: this.hamalatForm.value.amount, count : 1}), 'donation')
  }
  addHamalatToCart() {
    // console.log(this.hamalatForm)
    if(this.hamalatForm.valid) this.addToCart(Object.assign(this.hamalatForm.value.case, {amount: this.hamalatForm.value.amount, count : 1}), 'donation')
  }


  donateKafalatNow() {
    if(this.kafalatForm.valid) this.donateNow(Object.assign(
      this.kafalatForm.value.guaranteed, 
      {
        amount: this.kafalatForm.value.amount,
        count : 1,
        logo:this.kafalatForm.value.guaranteed.category.logo
      })
    , 'sponsorship')
  }
  addKafalatToCart() {
    // console.log(this.kafalatForm)
    if(this.kafalatForm.valid) this.addToCart(Object.assign(this.kafalatForm.value.guaranteed, {amount: this.kafalatForm.value.amount, count : 1, logo:this.kafalatForm.value.guaranteed.category.logo}), 'sponsorship')
  }





  donateNow(data, type) {
    // console.log('affdodo')
    // console.log(data)
    if(this.checkAmount(data)) {
      this.addToCart(data, type);
      this.router.navigate(['/cart'])
    }
  }

  addToCart(data, type) {
    // if(this.checkAuth()) {
      
     if(this.checkAmount(data)){
        let d = data;
        d['itemType'] = type
        this.cartService.cartItems = d
     }
         // } 
  }

  checkAmount(data){
    if(!data.amount){
      this.translate.get('cart.no_amount').subscribe(res=>{
        this.cartService.presentToast(res,'danger');
      })
      return false;
    } 
    return true;
  }


}
