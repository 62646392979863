import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// import { MaterialModule } from './material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { IonicModule } from '@ionic/angular';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
// import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';
import { MomentModule } from 'ngx-moment';
import { RouterModule } from '@angular/router';
import { CurrencyPipe } from '../pipes/currency.pipe';
import { VideoPipe } from '../pipes/video.pipe';

@NgModule({
  declarations: [CurrencyPipe,VideoPipe],
  imports: [
    CommonModule,
    AnimateOnScrollModule.forRoot(),
    MomentModule,
    RouterModule
  ],
  exports:[
    ReactiveFormsModule,
    FormsModule,
    // MaterialModule,
    CommonModule,
    TranslateModule,
    IonicModule,
    InfiniteScrollModule,
    // NgxUsefulSwiperModule,
    AnimateOnScrollModule,
    NgxIntlTelInputModule,
    MomentModule,
    RouterModule,
    CurrencyPipe,
    VideoPipe

  ],
  schemas:[
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ]
})
export class SharedModule { }
