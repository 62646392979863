import { Component, OnInit, Input } from '@angular/core';
import { Api, site_url } from 'src/app/services/api.endpoints';
import { SharedService } from 'src/app/services/shared.service';
import { ActionSheetController, PopoverController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { SharePopoverComponent } from '../share-popover/share-popover.component';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  @Input('fab') fab:boolean;
  @Input('title') title
  @Input('url') url
  @Input('image') image
  @Input('description') description
  site_url = site_url
  constructor(
    public sharedService:SharedService,
    private actionSheet:ActionSheetController,
    private translateService:TranslateService,
    private socialShare:SocialSharing,
    private popoverController:PopoverController,
    private platform:Platform
  ) { }
  isMobile = false;
  ngOnInit() {
    this.image = Api.uploads + this.image
    if(this.platform.is('android') || this.platform.is('ios') || this.platform.is('cordova')) this.isMobile = true;

  }

  openActionSheet() {

    this.socialShare.share(this.title + ' \n' + this.description, this.image, this.site_url + this.sharedService.appLang + '/'  + this.url)
    
   
  }

  shareViaFacebook () {

    // console.log('Facebook',this.site_url + '/' +this.sharedService.appLang + this.url)
    // [url]="site_url + url" [title]="title" [image]="image" [description]="description"
    this.socialShare.shareViaFacebook(this.title + ' \n' + this.description, this.image, this.site_url + this.sharedService.appLang + '/'  + this.url)
  }
  shareViaWhatsapp () {
    this.socialShare.shareViaWhatsApp(this.title + ' \n' + this.description, this.image, this.site_url + this.sharedService.appLang + '/'  + this.url)
  }
  shareViaTwitter () {
    this.socialShare.shareViaTwitter(this.title + ' \n' + this.description, this.image, this.site_url + this.sharedService.appLang + '/'  + this.url)
  }
  shareViaInstagram () {
    this.socialShare.shareViaInstagram( this.title + ' \n' + this.description, this.image)

  }



  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: SharePopoverComponent,
      cssClass: 'other-shares',
      event: ev,
      translucent: true,
      componentProps:{
        data:{
          title: this.title,
          url:'/' +this.sharedService.appLang + this.url,
          image: this.image,
          description: this.description,
        }
      }
    });
    popover.present(); 
  }

}
