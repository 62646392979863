import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationEnd, NavigationStart, PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuard , LoginAuthGuard} from './guards/auth.guard';
import { site_url } from './services/api.endpoints';
import { SharedService } from './services/shared.service';

const langs = ['ar','en'];

function getAppLang(){
  let cachedLang = localStorage.getItem('appLang');
   if(!cachedLang){
    cachedLang = 'ar'
  };

  let path = window.location.pathname;
  // Not Empty path
  if(path != '/' ){
      // Check URL Language
      let pathItems = path.split('/')
      // Language exists
      if( langs.indexOf(pathItems[1]) != -1 ){
          cachedLang = pathItems[1];
      }
  }
  localStorage.setItem('appLang',cachedLang)
  return cachedLang;
}
var appLang = getAppLang()
 
 
const routes: Routes = [
  {
    path:'',
    redirectTo:"/home",
    pathMatch:'full'
  },
  {
    path: 'home', 
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'mobile-home',
    loadChildren: () => import('./pages/mobile-home/MobileHome.module').then( m => m.MobileHomePageModule)
  },
  {
    path: 'donations',
    loadChildren: () => import('./pages/hamalat/hamalat.module').then( m => m.HamalatPageModule)
  },
  // {
  //   path: 'e-learning',
  //   loadChildren: () => import('./pages/e-learning/e-learning.module').then( m => m.ELearningPageModule)
  // },
  // {
  //   path: 'sponsorship', 
  //   loadChildren: () => import('./pages/kafalat/kafalat.module').then( m => m.KafalatPageModule)
  // },
  {
    path:'auth',
    canActivate:[LoginAuthGuard],
    loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthModule)
  },
  {
    path: 'about-us',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./pages/news/news.module').then( m => m.NewsPageModule)
  },
  {
    path: 'cart',
    loadChildren: () => import('./pages/cart/cart.module').then( m => m.CartPageModule),
  },
  {
    path: 'story/:storyId',
    loadChildren: () => import('./pages/story-details/story-details.module').then( m => m.StoryDetailsPageModule)
  },
  {
    path:'profile',
    canActivate:[AuthGuard],
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfileModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./pages/contact-us/contact-us.module').then( m => m.ContactUsPageModule)
  },
  {
    path: 'zakat-calculator',
    loadChildren: () => import('./pages/zakat-calculator/zakat-calculator.module').then( m => m.ZakatCalculatorPageModule)
  },
  {
    path: 'donation-fields/:categoryId',
    loadChildren: () => import('./pages/donation-fields/donation-fields.module').then( m => m.DonationFieldsPageModule)
  },
  {
    path:'**',
    redirectTo:"/home",
    pathMatch:'full'
  }

];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers:[{provide: APP_BASE_HREF, useValue: getAppLang()}]
})
export class AppRoutingModule {
  constructor(private router:Router, private sharedDervice:SharedService){
    this.router.events.subscribe( (e:any) => {

      // Handle Router Navigation
      if(e instanceof NavigationStart ) {
        if(e.url.startsWith('/'+appLang)){
          let url = e.url.split('/'+appLang+'/');
          if(url.length == 1) 
            this.router.navigate(['/home'], { skipLocationChange: true })
          else
          this.router.navigate(['/'+url[1]], { skipLocationChange: true }); 
        }
      }
     
    })
  }
 }
