import { Component, OnInit } from '@angular/core';
import { NavParams, Platform } from '@ionic/angular';
import { SharedService } from 'src/app/services/shared.service';
import { site_url } from 'src/app/services/api.endpoints';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';

@Component({
  selector: 'app-share-popover',
  templateUrl: './share-popover.component.html',
  styleUrls: ['./share-popover.component.scss'],
})
export class SharePopoverComponent implements OnInit {
data;
site_url = site_url
buttons = [
  {
    name:"messenger",
    icon:"fas fa-facebook-messenger"
  }, 
  // {
  //   name: "telegram",
  //   icon:'fas fa-telegram-plane'
  // },
  {
    name:"email",
    icon:"fas fa-envelope"
  }, 
  // {
  //   name:"copylink",
  //   icon:"fas fa-copy"
  // },
  // {
  //   name:'print',
  //   icon:"fas fa-print"
  // }
  
]
isMobile = false;
  constructor(
    private navParams:NavParams,
    public sharedService:SharedService,
    private socialShare:SocialSharing,
    private platform:Platform
  ) { }
  ngOnInit() {
    if(this.platform.is('android') || this.platform.is('ios')  ) this.isMobile = true;
    this.data = this.navParams.get('data');
    // console.log(this.data)
  }

  doMobileShare(title) {
    if(title === 'messenger') this.socialShare.shareViaFacebookWithPasteMessageHint(this.data.title + ' \n' + this.data.description, this.data.image, this.data.site_url + this.data.url)
    if(title === 'email') this.socialShare.shareViaEmail(this.data.title + ' \n' + this.data.description, this.data.image, this.data.site_url + this.data.url)
  }

}
