import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Push } from '@ionic-native/push/ngx';
import { Api } from './api.endpoints';
import { Platform } from '@ionic/angular';
// import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class DataService  {
  is_loged_in = false;
  user_data;
  deviceType = 'ios';
  deviceToken = 'sdds';
  filterSubject = new Subject();
  isLoading = false;
  
  constructor(
    private httpClient:HttpClient,
    private push:Push,
    private platform:Platform
  ) { 

  }


  setErrorImage(img,gender) {
    // console.log('setErrorImage',img)
    img.src= gender === 'female' ? 'assets/icon/profile-female.svg' : 'assets/icon/profile-male.svg'
  }
  
  get(url, data?) {
    return this.httpClient.get(url,{params:data})
  }

  post(url, data = {}) {
    return this.httpClient.post( url, data)
  }

  secure_get(url:string, dataParams?) {
    return this.httpClient.get( url, {params:dataParams, headers:this.setHeaders()})
  }

  secure_post(url:string, dataParams?) {
   // // console.log(dataParams);
    return this.httpClient.post( url, dataParams, {
      headers: this.setHeaders()
    })
  }

  secure_put(url:string, dataParams?) {
    return this.httpClient.put( url, dataParams, {
      headers: this.setHeaders()
    })
  }

  secure_delete(url:string, dataParams?) {
    return this.httpClient.delete(url, {params:dataParams, headers:this.setHeaders()})
  }

  secure_get_base(url:string, dataParams?) {
    return this.httpClient.get(url, {params:dataParams, headers:this.setHeaders()})
  }

  secure_post_base(url:string, dataParams?) {
    return this.httpClient.post(url, dataParams, {
      headers: this.setHeaders()
    })
  }

  secure_put_base(url:string, dataParams?) {
    return this.httpClient.put( url, dataParams, {
      headers: this.setHeaders()
    })
  }

  secure_delete_base(url:string, dataParams?) {
    return this.httpClient.delete(url, {params:dataParams, headers:this.setHeaders()})
  }



  setHeaders() {
    return new HttpHeaders({
      'Authorization':"Bearer " + localStorage.getItem('token'),
      "Access-Control-Allow-Origin":'*',
      // 'Accept':  'application/json'
      'Accept': 'application/json-patch+json'
    });
  }
  

  uploadFiles(data, endpoint){
    let that = this;
      return new Promise((resolve, reject) => {
        var formData: any = new FormData();
        var xhr = new XMLHttpRequest();
        for (const item in data) {
            formData.append( item, data[item]);
        }

      //  xhr.setRequestHeader = this.setHeaders();
          xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {
              if (xhr.status == 200) {
                // that.loading.dismiss();
                resolve(JSON.parse(xhr.response));
              } else {
                // that.loading.dismiss();
                reject(xhr.response);
              }
            }
        }
        xhr.open("POST",  endpoint, true);
        xhr.send(formData);
     });
  }




  uploadFile(data_file, postUrl:string, method='POST') {
    const myFormData:FormData = new FormData()
    for(let item in data_file) {
      myFormData.append(item, data_file[item]);
    }
    // myFormData.append('Authorization', "Bearer " + JSON.parse(localStorage.getItem('token'))) ;
    // console.log(myFormData)
    const config = new HttpRequest(method,  postUrl, myFormData, {
      reportProgress: true,
      headers: this.setHeaders()
    })
    this.httpClient.request( config )
    return this.httpClient.request( config ).toPromise()
  }



  p
  updateToken() {
    if(this.platform.is('android')) this.p = "android";
    if (this.platform.is('ios')) this.p = "ios";
    if(this.platform.is('android') || this.platform.is('ios')) {
      // // this.enable_background_mode();
      // (<any>window).skipLocalNotificationReady = true;
      this.push.init({
        "android": {
          "senderID": "SENDER-ID"
        },
        "ios": {"alert": "true", "badge": "true", "sound": "true"}, 
        "windows": {} 
      }).on('registration').subscribe(res => {
        this.secure_post(Api.updateToken, {
          device_token:res.registrationId,
          platform: this.p
        }).subscribe()
      })
    }
  }

}
