import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-main-kafala-card',
  templateUrl: './main-kafala-card.component.html',
  styleUrls: ['./main-kafala-card.component.scss'],
})
export class MainKafalaCardComponent implements OnInit {
  kafalaForm:FormGroup;
  @Input('cartMode') cartMode;
  @Input('cardData') cardData:any = {};
  constructor(
    private cartService:CartService
  ) { }

  ngOnInit() {
    this.kafalaForm = new FormGroup({
      category:new FormControl(this.cardData.category || ''),
      amount:new FormControl(this.cardData.amount || ''),
      quantity:new FormControl(this.cardData.quantity || ''),
      id:new FormControl(this.cardData.id || '')
    })

    if(this.cartMode) {
      this.kafalaForm.valueChanges.subscribe(r => {
        let d = this.kafalaForm.value;
        d['itemType'] = 'main-sponsorship'
        this.cartService.cartItems = d
      })
    }
   
  }

  
}
