import { AfterViewInit, Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Router, NavigationEnd } from '@angular/router';
import { CartService } from 'src/app/services/cart.service';
import { Platform, ModalController } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { Api } from 'src/app/services/api.endpoints'
import { UrgentCasesComponent } from '../urgent-cases/urgent-cases.component';
import {Location} from '@angular/common';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit,AfterViewInit {
  constructor(
    public sharedService:SharedService ,
    public router: Router,
    public cartService:CartService,
    private platform:Platform,
    private iab:InAppBrowser,
    private modalCtrl:ModalController,
    public location: Location
    
    ) { }
  ngAfterViewInit(): void {
    this.sharedService.NavElements = document.getElementsByTagName("nav")
    // console.log(this.sharedService.NavElements)
  }

  fastDonate(){
    this.iab.create(Api.fast_donate).show();
  }

  canGoBack = false;

  ngOnInit() {
    this.canGoBack = this.router.navigated;

  }


  routeToProfile() {
    if(this.platform.is('cordova')) this.router.navigate([''])
    else this.router.navigate(['/profile/mob'])
  }

  openUrgent() {
    this.modalCtrl.create({
      component:UrgentCasesComponent,
      cssClass:'urgentModal'
    }).then(m => m.present())
  }
  doLogout() {
    this.sharedService.isLoggedIn = false;
    localStorage.removeItem('token')
    localStorage.removeItem('profile')
    this.router.navigate(['/home'])
  }

}
