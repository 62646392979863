import { Injectable } from '@angular/core';
import { AlertController , ToastController} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
 
@Injectable({
  providedIn: 'root'
})
export class CartService {
 

  _cartItems = {
    "case":{},
    "cat":{}
  };

  messages = {
    'itemAdded':'',
    'itemRemoved':'',
    'itemUpdated':'',
  }
  constructor(
    private alertCtrl:AlertController,
    private toastController: ToastController,
    private translate:TranslateService
  ) { 
    if(localStorage.getItem('user_cart')){
      this._cartItems = JSON.parse(localStorage.getItem('user_cart'));
    }

    this.getCartItems;


    // Translate Messages Object
    Object.keys(this.messages)
    .map(key =>{
      this.translate.get('cart.'+key).subscribe(res=>{
        this.messages[key] = res;
      })
    });

  }

  set cartItems(data:any) {
    if( this._cartItems[data.itemType][data.id+'_'+data.amount] ) {
       if(data.donation_type != 2 ) data.count+=1;
        this._cartItems[data.itemType][data.id+'_'+data.amount] = JSON.parse(JSON.stringify(data));
        this.presentToast(this.messages.itemUpdated);
    }else {
      this._cartItems[data.itemType][data.id+'_'+data.amount] = JSON.parse(JSON.stringify(data));;
      this.presentToast(this.messages.itemAdded);
    }
    localStorage.setItem('user_cart',JSON.stringify(this.cartItems));
    this.getCartItems;
  }


  deleteFromCart(data){
    

   // this._cartItems[data.itemType][data.id+'_'+data.amount];

    delete this._cartItems[data.itemType][data.id+'_'+data.amount]; 
    this.presentToast(this.messages.itemRemoved,'success');

    localStorage.setItem('user_cart',JSON.stringify(this.cartItems))
  }
  async presentToast(msg,type='success') {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000,
      color:(type=='danger')?"danger":"success",
      position:"top"
    });
    toast.present();
  }


  get cartItems() {
    return this._cartItems
  }

  cart_items = [];
 
  _totalAmount=0;

  get getCartItems () {
    this._totalAmount = 0;
    this.cart_items = [];
    for(let item in this._cartItems.case) {
        this.cart_items.push(this._cartItems.case[item]);
        let x = parseInt(this._cartItems.case[item].amount) ;
        this._totalAmount += x;
    }
    
    for(let item in this._cartItems.cat) {
      this.cart_items.push(this._cartItems.cat[item]);
      let x = parseInt(this._cartItems.cat[item].amount) ;
      this._totalAmount += x;
    }
   return this.cart_items;
}


get getCartForPayment () {

  let items = [];
  for(let item in this._cartItems.case) {
    
    items.push('d_'+item+'_1_'+this._cartItems.case[item]['title_'+localStorage.getItem('appLang')]);
  }
  
  for(let item in this._cartItems.cat) {
  
    items.push('s_'+item+'_1_'+this._cartItems.cat[item]['name_'+localStorage.getItem('appLang')]);
  }
  let r = items.toString();
 return r;
}

  get totalAmount(){
    return this._totalAmount;
  }
  get totalItems(){
    return this.cart_items.length;
  }



  emptyCart() {
    // this.alertCtrl.create({
    //   message:'Do You Want To Clear The Cart ?',
    //   buttons:[
    //     {
    //       text:'Remove',
    //       handler:() => {
    //      //    this._cartItems  = {};
    //          localStorage.removeItem('user_cart');
    //       }
    //     },
    //     {
    //       text:'cancel',
    //       role:'cancel'
    //     }
    //   ]
    // }).then(a => a.present())
    localStorage.removeItem('user_cart');
    this.cart_items = [];
    this._cartItems = {
      "case":{},
      "cat":{}
    };
    this._totalAmount = 0;

  }
  
}
