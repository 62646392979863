import { Component, OnInit } from '@angular/core';

import { Platform, MenuController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router, NavigationEnd } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from './services/shared.service';
import { UrgentCasesComponent } from './components/urgent-cases/urgent-cases.component';
import { CartService } from './services/cart.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit{
  currencySelect 
  languageSelect
  activeTab = 'home'; 
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public translate: TranslateService,
    public sharedService:SharedService,
    private menu: MenuController,
    private modalCtrl:ModalController,
    public cartService:CartService

  ) {
    this.sharedService.setLang();
    this.currencySelect = {
      header: this.sharedService.appLang == 'ar' ? 'اختر العملة' : 'Choose currency',
      translucent: true
    }
    this.languageSelect = {
      header: this.sharedService.appLang == 'ar' ? 'اختر لغة التطبيق' : 'Choose app language',
      translucent: true
    }
    this.initializeApp();

    this.router.events.subscribe(() => {
      this.sharedService.showSmallNav = null;
    })
  }
  
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.sharedService.checkAuthNoRoute();
    });
  }

  ngOnInit() {
  }

  routeToProfile() {
    if(this.platform.is('cordova')) this.router.navigate(['/profile/mob'])
    else this.router.navigate(['/profile/mob'])
  }

  onResize(event){
    if(event.target.innerWidth < 787 ) {
      this.sharedService.mobile = true;
      this.menu.enable(true,'mainMenu');
    }
    else {
      this.sharedService.mobile = false;
      this.menu.enable(false,'mainMenu');
    }
  }
   

  changeEv(ev) {
    // console.log(ev)
  }
  doLogout() {
    this.sharedService.isLoggedIn = false;
    localStorage.removeItem('token')
    localStorage.removeItem('profile')
    this.router.navigate(['/home'])
  }

  switchLang(ev) {
    // console.log(ev)
    this.sharedService.appLang  = ev
    localStorage.setItem('appLang', ev)
    this.sharedService.setLang()
    location.reload();
  }

  openUrgent() {
    this.modalCtrl.create({
      component:UrgentCasesComponent,

    }).then(m => m.present())
  }


  navigate(_route,tab){
    this.activeTab = tab;
    this.router.navigate([_route]);
  }

  
}
