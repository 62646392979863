import { Component, OnInit, Input } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

@Component({
  selector: 'app-socialmedia',
  templateUrl: './socialmedia.component.html',
  styleUrls: ['./socialmedia.component.scss'],
})
export class SocialmediaComponent implements OnInit {

  @Input('colored') colored = false;
  constructor(public sharedService:SharedService , private iab: InAppBrowser) { }

  ngOnInit() {}


  openSocial(network){
    this.iab.create(this.sharedService.assets.settings['site'][network]).show();
  }
   

}
