//const local_url = 'assets/backend/';

// export const base_url = 'http://127.0.0.1:8000';
//export const base_url = "http://761d906e04be.ngrok.io";


// Production Links
export const base_url = "https://waqf.thaoman.com/backend";
export const site_url = 'https://waqf.thaoman.com';
const StorageURL = site_url + '/storage/';

// Development Links
// export const base_url = 'http://127.0.0.1:8000';
// export const site_url = 'http://localhost:8000';
// const StorageURL = base_url + '/storage/';


const API_URL = base_url + '/api/';  

export const Map_API_Key = 'AIzaSyDOwA4veQGxQUjLk4oZnSdTANg9jhskUMk';
export const STRIPE_KEY = "pk_test_51HT7k1E3KidDxE0VCf9YxzkwCdtKQmVRuk2Kp3SSIJxbG1bWxDGt3bQs1kBK0aO8tDity2rhoK25m5B19KAugS1100CjFKZwvf";

const Api = {
    API_URL: API_URL,
    uploads: StorageURL,
    home:API_URL+"home",
    assets:API_URL+"assets",
    news:API_URL+"news",
    cases:API_URL+"cases",
    login: API_URL+"auth/loginbyphone",
    activate: API_URL+"auth/activate",
    loginbyemail: API_URL + "auth/loginbyemail",
    signupByEmail: API_URL + "auth/signup",
    sponsorships: API_URL + 'sponsorships',
    cources: API_URL + "cources",
    courseApply:API_URL + "cources/apply",
    courseCancel: API_URL + "cources/cancel",
    profile : API_URL + "profile",
    meetings:API_URL + "meetings",
    bankpayment:API_URL + "payment",
    uploadConfirmation:API_URL + "confirm-payment",
    urgentCases:API_URL +"cases/urgent",
    mobilehome:API_URL +"mobilehome",
    fast_donate:"https://www.donate.om/WebAppAR/Home/CharitySelection.aspx?d=21&csrt=16984150314464846167",
    updateToken:API_URL + 'update-token',
    createInvoice:API_URL + 'invoice',
    confirm_order:API_URL + 'confirm-order',
}


export {API_URL , Api , StorageURL}