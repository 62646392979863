import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SharedService } from 'src/app/services/shared.service';
import { UiService } from 'src/app/services/ui.service';
import { Api } from 'src/app/services/api.endpoints';
import { ModalController, IonSlides } from '@ionic/angular';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-urgent-cases',
  templateUrl: './urgent-cases.component.html',
  styleUrls: ['./urgent-cases.component.scss'],
})
export class UrgentCasesComponent implements OnInit {
  @ViewChild ('slides') mySlide:IonSlides;
  @Input('isModal') isModal:boolean = true;
  async swipeNext() {
    this.mySlide.slideNext();
    this.activeIndex = await this.mySlide.getActiveIndex()

  }
  async swipePrev() {
    this.mySlide.slidePrev();
    this.activeIndex = await this.mySlide.getActiveIndex()
  }

  
  cases;
  config1 = {
    
    spaceBetween: 30,
    slidesPerView : 1.2,
    direction:'horizontal',
    centeredSlides: true,
    centeredSlidesBounds:true,
    autoplay:false,
    breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
         
        }
  };

  constructor(
    private dataService:DataService,
    private sharedService:SharedService,
    private uiService:UiService,
    private modalCtrl:ModalController

  ) { }

  ngOnInit() {
    //this.uiService.show_loading();
    this.dataService.get(Api.urgentCases)
    .pipe(finalize(() => {}))
    
    .subscribe(r => {
      // console.log(r)
      this.cases = r['data']
      // for(let i =0; i<this.cases.length; i++) {
      //   this.cases.push(r['data'][0])
      // }
     
      this.mySlide.getActiveIndex().then(i => {
        // console.log('active', i)
      })
    })
  }

  closeModal() {
    this.modalCtrl.dismiss()
  }

  navToIndex(i) {
    this.mySlide.slideTo(i)
    this.activeIndex = i

  }
  activeIndex = 0;
  async slideChanged() {
    this.activeIndex = await this.mySlide.getActiveIndex()
    // console.log(await this.mySlide.getActiveIndex())
  }
}
